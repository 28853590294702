export const load = state => state.load
export const modalSair = state => state.modalSair
export const modalGraos = state => state.modalGraos
export const modalSairCadastro = state => state.modalSairCadastro
export const drawerDetalhes = state => state.drawerDetalhes
export const dadosUsuario = state => state.dadosUsuario

export const modulos = state => state.modulos
export const plantas = state => state.plantas
export const unidadesArmazenamento = state => state.unidadesArmazenamento
export const condicoesClimaticas = state => state.condicoesClimaticas
export const leituraTermal = state => state.leituraTermal
export const clima = state => state.clima
export const receita = state => state.receita
export const detalhes = state => state.detalhes