var token;

if (localStorage.getItem('token')) {
  new Promise((resolve) => {
    token = `Bearer ${localStorage.getItem('token')}`;
    resolve();
  }).catch(() => {
    localStorage.clear();
  });
}

export default {
  API_BASE_URL_DEV: 'https://api.sinapsesolucoes.com/',
  API_BASE_URL_PROD: 'https://api.sinapsesolucoes.com/',

  ACCESS_TOKEN: token,
};
