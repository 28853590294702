import axios from 'axios'
import cfg from '../config'

class Service {
  constructor() {
    const BASE_URL_ENVIRONMENT =
      window.location.hostname === 'localhost'
        ? cfg.API_BASE_URL_DEV
        : cfg.API_BASE_URL_PROD
    const service = axios.create({
      baseURL: BASE_URL_ENVIRONMENT,
      headers: {
        'Content-Type': 'application/json'
      }
    })
    service.interceptors.response.use(this.handleSuccess, this.handleError)
    this.service = service
  }

  handleSuccess(response) {
    return response
  }

  handleError = (error) => {
    return Promise.reject(error)
  }

  //************************ verbos que não precisam de token no header ***************************/
  getPublic(path, payload = null) {
    return new Promise((resolve, reject) => {
      this.service
        .request({
          method: 'GET',
          url: path,
          params: payload
        })
        .then((response) => resolve(response.data))
        .catch((e) => reject(e))
    })
  }


  //************************ verbos que precisam de token no header ***************************/
  get(path, payload = null) {
    return new Promise((resolve, reject) => {
      this.service
        .request({
          method: 'GET',
          url: path,
          params: payload,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then((response) => resolve(response.data))
        .catch((e) => reject(e))
    })
  }

  post(path, payload) {
    return new Promise((resolve, reject) => {
      this.service
        .request({
          method: 'POST',
          url: path,
          data: payload,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then((response) => resolve(response.data))
        .catch((e) => reject(e))
    })
  }

  put(path, payload = null) {
    return new Promise((resolve, reject) => {
      this.service
        .request({
          method: 'PUT',
          url: path,
          data: payload,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then((response) => resolve(response.data))
        .catch((e) => reject(e))
    })
  }

  delete(path, payload = null) {
    return new Promise((resolve, reject) => {
      this.service
        .request({
          method: 'DELETE',
          url: path,
          data: payload,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then((response) => resolve(response.data))
        .catch((e) => reject(e))
    })
  }
}

export default new Service()