export const initialState = () => {
  return {
    load: false,
    modalSair: false,
    modalGraos: false,
    modalSairCadastro: false,
    drawerDetalhes: false,
    dadosUsuario: '',
    modulos: [],
    plantas: [],
    unidadesArmazenamento: [],
    leituraTermal: [],
    clima: {},
    receita: {},
    detalhes: []
  }
}

export const state = JSON.parse(JSON.stringify(initialState()))