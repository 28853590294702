import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/Default'),
    children: [
      {
        path: '',
        component: () => import('@/views/Home.vue'),
        name: 'Principal',
        meta: {
          title: 'Sinapse - Home',
          noCache: true
        }
      },
      {
        path: '/localMonitoramento/:abreviacao',
        component: () => import('@/views/LocalMonitorar.vue'),
        name: 'Local',
        meta: {
          title: 'Sinapse - Monitoramento',
          noCache: true
        }
      },
      {
        path: '/produtos/:abreviacao/:id',
        component: () => import('@/views/Produto.vue'),
        name: 'Produtos',
        meta: {
          title: 'Sinapse - Produtos',
          noCache: true
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue')
  }
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
