<template lang="pug">
#app(v-loading="load")
  router-view
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['load'])
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;700;900&display=swap');
.v-modal{
  z-index: 0 !important;
}

html * {
  font-family: 'Lato', sans-serif;
}

html, body{
  width: 100%;
  height: 100%;
  margin: 0;
}

#app {
  width: 100%;
  user-select: none;

  nav{
    a{
      margin-right: 20px;
    }
  }
}

</style>
