import Vue from 'vue'
import Vuex from 'vuex'
import termometria from '@/store/modules/termometria'
import {state} from './state'
import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'

Vue.use(Vuex)

const modules = {
  termometria
}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules
})
