import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import Element from 'element-ui';
import './element-variables.scss';
import locale from 'element-ui/lib/locale/lang/pt-br';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');

Vue.use(Element, { locale });
Vue.use(moment);

Vue.config.productionTip = false;

const url = 'https://api.sinapsesolucoes.com/';

Vue.prototype.$url = url;
axios.defaults.baseURL = url;

if (localStorage.getItem('token')) {
  new Promise((resolve) => {
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${localStorage.getItem('token')}`;
    resolve();
  }).catch(() => {
    localStorage.removeItem('token');
    window.location = '/#/login';
  });
} else {
  localStorage.removeItem('token');
  window.location = '/#/login';
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
