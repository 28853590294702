export const setLoad = (state, payload) => state.load = payload
export const setModalSair = (state, payload) => state.modalSair = payload
export const setModalGraos = (state, payload) => state.modalGraos = payload
export const setModalSairCadastro = (state, payload) => state.modalSairCadastro = payload
export const setDrawerDetalhes = (state, payload) => state.drawerDetalhes = payload
export const setUsuario = (state, payload) => state.dadosUsuario = payload
export const setModulos = (state, payload) => state.modulos = payload
export const setPlantas = (state, payload) => state.plantas = payload
export const setUnidadesArmazenamento = (state, payload) => state.unidadesArmazenamento = payload
export const setLeituraTermal = (state, payload) => state.leituraTermal = payload
export const setClima = (state, payload) => state.clima = payload
export const setReceita = (state, payload) => state.receita = payload
export const setDetalhes = (state, payload) => state.detalhes = payload