import Service from "@/utils/service"
import moment from "moment"

export const setLoad = ({ commit }, payload) => {
  commit('setLoad', payload)
}
export const setModalSair = ({ commit }, payload) => {
  commit('setModalSair', payload)
}
export const setModalGraos = ({ commit }, payload) => {
  commit('setModalGraos', payload)
}
export const setModalSairCadastro = ({ commit }, payload) => {
  commit('setModalSairCadastro', payload)
}
export const setDrawerDetalhes = ({ commit }, payload) => {
  commit('setDrawerDetalhes', payload)
}

export const recuperarSenha = async ({ dispatch }, email) => {
  const resp = await Service.getPublic(`/publico/area-cliente/recuperacao-senha/${email}`)
  return resp
}

export const getLogin  = async ({ dispatch }, params) => {
  const resp = await Service.getPublic('/publico/area-cliente/login', params)
  return resp.token
}

export const getUsuario = async ({ commit }) => {
  const resp = await Service.get('/publico/dados-token')

  if(resp.length){
    resp[0].map(d => {
      if(d.dados) commit('setUsuario', d.dados[0])
      if(d.modulos) {
        d.modulos.map(m => {
          m.abreviacao = m.modulo.substr(0, 1)

          return m
        })
        localStorage.setItem('modulos', JSON.stringify(d.modulos))
        commit('setModulos', d.modulos)
      }
    })
  }
}

export const putVersao = async ({ dispatch }, params) => {
  const resp = await Service.put('publico/area-cliente/versao', params)

  return resp
}

export const getPlantas = async({ commit }) => {
  const resp = await Service.get('/area-cliente/termometria/lista-plantas')
  localStorage.setItem('plantas', JSON.stringify(resp))
  commit('setPlantas', resp)
}

export const getUnidadesArmazenamento = async({ commit }, idPlanta) => {
  const resp = await Service.get(`/area-cliente/termometria/planta/${idPlanta}/unidades-armazenamento`)
  if(resp.length){
    resp.map(m => {
      m.silo_indisponivel = 'A'
      m.hoje = moment().format('L')
      if(!m.data_ultima_leitura_registrada) m.silo_indisponivel = 'I'
      if(m.data_ultima_leitura_registrada) {
        m.data_ultima_leitura_registrada = moment(m.data_ultima_leitura_registrada).format('L')
        const ultimaData = moment(m.data_ultima_leitura_registrada, "DD/MM/YYYY").format()
        const dataAtual = moment(m.hoje, "DD/MM/YYYY").format()
        if(ultimaData < dataAtual) m.silo_indisponivel = 'I'
        else m.silo_indisponivel = 'A'
      }
      return m
    })
  }

  localStorage.setItem('unidadesArmazenamento', JSON.stringify(resp))
  commit('setUnidadesArmazenamento', resp)
}

export const getLeituraTermal = async({ commit }, params) => {
  const { id, id_planta } = JSON.parse(localStorage.getItem('unidade'))
  const resp = await Service.post(`/area-cliente/termometria/planta/${id_planta}/unidade-armazenamento/${id}/leituras-temperatura/vista-aerea`, params)
  resp.mapa_calor = []
  resp.intermediario = null
  resp.lateral = null
  if(resp.vista_aerea.length){
    resp.vista_aerea.map(vistaAerea => {
      if(vistaAerea.central&&vistaAerea.central.length) {
        vistaAerea.central.map(central => {
          resp.mapa_calor.push(central)
        })
      }
      if(vistaAerea.intermediaria&&vistaAerea.intermediaria.length) {
        vistaAerea.intermediaria.map(intermediaria => {
          resp.mapa_calor.push(intermediaria)
        })
        resp.intermediario = vistaAerea.intermediaria.length
      }
      if(vistaAerea.lateral&&vistaAerea.lateral.length) {
        vistaAerea.lateral.map(lateral => {
          resp.mapa_calor.push(lateral)
        })
        resp.lateral = vistaAerea.lateral.length
      }
    })
    commit('setLeituraTermal', resp)
  }else commit('setLeituraTermal', [])
}

export const getClima = async({ commit }) => {
  const { id_planta } = JSON.parse(localStorage.getItem('unidade'))
  const resp = await Service.get(`/area-cliente/termometria/planta/${id_planta}/estacao-metereologica`)
  
  resp[0].data_hora_leitura = moment(resp[0].data_hora_leitura).format('LLLL')
  
  commit('setClima', resp[0])
}

export const getReceita = async({ commit }) => {
  const { id_planta, id } = JSON.parse(localStorage.getItem('unidade'))
  const resp = await Service.get(`/area-cliente/termometria/planta/${id_planta}/unidade-armazenamento/${id}/receitas`)

  const hora_leitura = resp.ultima_leitura_estacao_metereologica[0].data_hora_leitura
  resp.ultima_leitura_estacao_metereologica[0].data_hora_leitura = moment(hora_leitura).format('LT')
  resp.receita_ativa = ''
  if(resp.receitas_aeracao.length){
    resp.receita_ativa = resp.receitas_aeracao.find(e => e.status == 'A')
  }
  
  commit('setReceita', resp)
}

export const setCadastroReceita = async({ commit }, params) => {
  const { id_planta, id } = JSON.parse(localStorage.getItem('unidade'))
  const resp = await Service.post(`/area-cliente/termometria/planta/${id_planta}/unidade-armazenamento/${id}/receita`, params)
}

export const updateReceita = async({ commit }, params) => {
  const dados = {
    nome: params.nome,
    temperaturaMinima: params.temperatura_minima,
    temperaturaMaxima: params.temperatura_maxima,
    horarioInicio: params.hora_inicial,
    horarioFim: params.hora_final,
    umidadeMinima: params.umidade_minima,
    umidadeMaxima: params.umidade_maxima,
    pontoOrvalhoLimite: params.ponto_orvalho,
    status: params.status,
    diasSemana: params.dias_semana,
    chuva: params.considerar_chuva,
    
    tempSiloTipoSetPoint: params.tempSiloTipoSetPoint,
    tempSiloSetPoint: params.tempSiloSetPoint,
    tempSiloLimite: params.tempSiloLimite,
    tempSiloHabilita: params.tempSiloHabilita,
  }
  const { id_planta, id } = JSON.parse(localStorage.getItem('unidade'))
  await Service.put(`/area-cliente/termometria/planta/${id_planta}/unidade-armazenamento/${id}/receita/${params.id}`, dados)
}

export const updateGraosArmazenados = async({ commit }, params) => {
  const { id_planta, id } = JSON.parse(localStorage.getItem('unidade'))
  await Service.put(`/area-cliente/termometria/planta/${id_planta}/unidade-armazenamento/${id}/grao-armazenado?grao=${params}`)
}

export const getUltimaAtualizacao = async({ commit }) => {
  const planta = JSON.parse(localStorage.getItem('planta'))
  const { id_planta } = planta.dados_planta
  const resp = await Service.get(`/area-cliente/termometria/planta/${id_planta}/data-hora-ultima-atualizacao-dados`)

  return resp
}

export const getStatusConexao = async({ commit }) => {
  const { id_planta, id } = JSON.parse(localStorage.getItem('unidade'))
  const resp = await Service.get(`/area-cliente/termometria/planta/${id_planta}/unidade-armazenamento/${id}/status-conexao`)

  return resp
}

export const getDetalhes = async({ commit }) => {
  const { id_planta, id } = JSON.parse(localStorage.getItem('unidade'))
  const resp = await Service.get(`/area-cliente/termometria/planta/${id_planta}/unidade-armazenamento/${id}/detalhes`)

  commit('setDetalhes', resp)
}